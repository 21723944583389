import { render, staticRenderFns } from "./logistics-insurance.vue?vue&type=template&id=2f63b4b1&scoped=true&"
import script from "./logistics-insurance.vue?vue&type=script&lang=ts&"
export * from "./logistics-insurance.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f63b4b1",
  null
  
)

export default component.exports