













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'logistics-insurance',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  // mounted() {
  //   console.log(this.data.watchmanFormData.AGBinsuranceFile.originalName);
  // },
});
